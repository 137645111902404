import React from 'react'
import PhysicianLandingPage from 'containers/physician-landing-page'

const MercyArdmoreLandingPage = () => (
  <PhysicianLandingPage
    physician="Mercy Ardmore OBGYN"
    institution="Mercy Ardmore OBGYN"
    referralCode="MERCYARDMORE"
    discountAmount="5"
  />
)

export default MercyArdmoreLandingPage
